import React from 'react'
import { ComingSoonContainer, Text } from './styles'
const ComingSoon = () => {
  return (
    <ComingSoonContainer>
    <Text>
        Coming Soon...
    </Text>

    </ComingSoonContainer>
  )
}

export default ComingSoon