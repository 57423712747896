import styled from 'styled-components';
import {motion } from 'framer-motion'



export const HomeContainer = styled(motion.div)`
/* overflow-x: hidden; */

    `
export const AboutContainer = styled(motion.div)`
background:#1e1e1e;


    `


export const LocationsContainer = styled(motion.div)`
background:#1e1e1e;


    `

export const ContactContainer = styled(motion.div)`
background:#1e1e1e;


    `

export const SingleLocationContainer = styled(motion.div)`
background: #1e1e1e;
    `

// export const PageTransition = styled(motion.div)`
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: var(--black);
//     z-index: 100;
//     height: 100vh;
// font-size:10vw;
// color:#fff;
//     `
